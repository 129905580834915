import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ["content"]
  }

  show() {
    this.element.classList.add('show')
  }

  hide(event) {
    event.preventDefault()

    this.element.classList.remove('show')
    this.contentTarget.innerHTML = ''
  }
}