import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ["audio"]
  }

  connect() {
    this.audioTarget.loop = false
  }

  play(event) {
    event.preventDefault()

    this.audioTarget.play()
  }
}